<script setup lang="ts">
const globalContentStore = useGlobalContentStore()
const { homeComponentId } = storeToRefs(globalContentStore)
</script>

<template>
  <main class="index-page">
    <ContentPage :identifier="homeComponentId" />
  </main>
</template>

<style scoped lang="scss">
.index-page {
  display: flex;
  flex-direction: column;
}
</style>
